<template>
  <div>
    <h1>Update Product # {{ productId }} Form</h1>
    <a @click="$router.back()">Back</a>
    <div
      v-if="productData"
      class="mb-5 mt-2"
    >
      <p>product: {{ productData.title.en }}</p>
    </div>

    <product-form
      v-if="productData"
      mode="create"
      :resource="productData"
      :loading="loading"
      :errors="errors"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import { useExtractErrors, useNotifySuccess } from '@/composables'
import router from '@/router'
import store from '@/store'
import { updateProduct } from '@api/product'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'
import useProduct from '../useProduct'
import ProductForm from './ProductForm.vue'

export default {
  components: { ProductForm },

  setup() {
    const { loadProduct, productData } = useProduct()

    const productId = router.currentRoute.query.product_id

    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      loading.value = true

      await updateProduct(productId, { ...form })
        .then(res => {
          console.log('form submit', form, res)
          router.push({
            name: 'product-view',
            params: { id: productId },
          })
          useNotifySuccess({ content: 'Update Succeeded.' })
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })

      loading.value = false
    }

    loadProduct(productId).then(res => {
      const product = res.data.data
      store.dispatch('merchant/setCurrent', product.merchant)
    })

    return {
      productData,
      productId,
      errors,
      loading,
      handleSubmit,
    }
  },
}
</script>
